import React, { useState } from 'react';
import '../app.css';
// import { ReactComponent as Camera } from "../assets/icons/camera.svg";
import { ReactComponent as Layers } from '../assets/icons/layers.svg';
import { ReactComponent as CameraBtn } from '../assets/icons/cameraBtn.svg';

const PhotoMode = ({ take_photo }) => {
  const [isShow, setIsShow] = useState(true);
  const [activeMode, setActiveMode] = useState(1);
  const showPhotoMode = () => {
    setIsShow((prev) => !prev);
  };
  /*const changeActiveMode = (value) => {
    setActiveMode(value);
  };
  function getOS() {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes('Mac') && 'ontouchend' in document)
    )
      return 'iOS';

    var i,
      os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], 'i').test(uA)) return os[i];
  }*/
  return (
    <div className="container">
      <Layers className="base-icon camera-icon" onClick={showPhotoMode} />
      <div>
        {isShow && (
          <>
            <CameraBtn
              className="camera-btn"
              id="camera-btn"
              onClick={take_photo}
            />
            <div className="container-change-action">
              <p
                className={`${activeMode === 1 ? '' : 'no-active-mode'}`}
                onClick={() => setActiveMode(1)}
              >
                ФОТО
              </p>
              {/* {getOS() !== "iOS" && (
                <p
                  className={`${activeMode === 2 ? "" : "no-active-mode"}`}
                  onClick={() => setActiveMode(2)}
                >
                  ВИДЕО
                </p>
              )} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhotoMode;
