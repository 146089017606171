import axios from 'axios';

import { API_URL } from './api-url';
import { getMobileOperatingSystem } from './check-os';
const os = getMobileOperatingSystem();

export const getData = async (id) => {
  let dataItemData;
  return await axios
    .get(`${API_URL}/data-item/${id}`)
    .then((data) => {
      if (data?.data?.filePath) {
        return {
          ...data,
          data: {
            ...data.data,
            filePath: `${data.data.filePath}/${os}/web`,
          },
        };
      }
      dataItemData = data;
      return data;
    })
    .then((resp) =>
      axios
        .get(resp.data.filePath)
        .then((fileResponse) => {
          return resp;
        })
        .catch((e) => {
          if (e?.response?.status === 402) {
            return {
              ...dataItemData,
              needPayment: true,
            };
          }
          return e;
        })
    );
};
