import React, { useState } from 'react';
import MindARVideo from './mindar-video';
import MindARModel from './mindar-model';
import MindARImage from './mindar-image';
import MindARVideoAlfa from './mindar-video-alfa';
import { SplashScreen } from './splash-screen';

const MindARBase = ({ data }) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [showMindAr, setShowMindAr] = useState(false);

  const handleHideSplash = () => {
    setTimeout(() => setShowSplashScreen(false), 500);
    setShowMindAr(true);
  };

  return (
    <>
      <SplashScreen show={showSplashScreen} onHide={handleHideSplash} />
      {showMindAr && (
        <>
          {data?.type?.codeName === 'video' && !data.alphaChannel && (
            <MindARVideo data={data} />
          )}
          {data?.type?.codeName === 'video' && data.alphaChannel && (
            <MindARVideoAlfa data={data} />
          )}
          {data?.type?.codeName === 'object' && <MindARModel data={data} />}
          {(data?.type?.codeName === 'image' ||
            data?.type?.codeName === 'widget') && <MindARImage data={data} />}
        </>
      )}
    </>
  );
};

export default MindARBase;
