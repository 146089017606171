import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./app.css";
import Base from "./components/base";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Base />} />
        <Route path=":id" element={<Base />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
