import React, { useEffect, useRef, useState } from 'react';
import PhotoMode from './photo-mode';

const MindARVideo = ({ data }) => {
  const sceneRef = useRef(null);
  const sceneWrapperRef = useRef(null);
  const [firstView, setFirstView] = useState(true);
  const [haveOne, setHaveOne] = useState(false);
  const [haveClick, setClick] = useState(false);
  const [procent, setProcent] = useState(1);

  async function getMediaURLForTrack(texture_to_update, passed_url) {
    await fetch(passed_url, { method: 'GET' }).then((response) => {
      texture_to_update.src = response.url;
    });
  }

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems['mindar-image-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });

    var video = document.getElementById('myvideo');
    video.addEventListener(
      'loadedmetadata',
      function (e) {
        var width = this.videoWidth,
          height = this.videoHeight;
        console.log('width', width);
        console.log('height', height);
        setProcent(height / width);
      },
      false
    );
    video.crossOrigin = 'anonimous';

    getMediaURLForTrack(video, data?.filePath);

    return () => {
      arSystem.stop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const video = document.querySelector('#myvideo');
    const exampleTarget = document.querySelector('#example-target');

    const handleTargetFound = () => {
      setFirstView(false);
      if (haveClick) {
        video.play();
      }
    };

    const handleTargetLost = () => {
      setFirstView(true);
      video.pause();
    };

    exampleTarget.addEventListener('targetFound', handleTargetFound);
    exampleTarget.addEventListener('targetLost', handleTargetLost);

    const handleWindowClick = (event) => {
      if (!event.target.closest('#myvideo') && !haveClick && !firstView) {
        video.play();
        setHaveOne(true);
        setClick(true);
      }
    };

    window.addEventListener('click', handleWindowClick);

    const examplePlane = document.querySelector('#example-plane');

    examplePlane.addEventListener('click', (event) => {
      if (haveOne && haveClick && !firstView && data?.actionUrl !== null) {
        video.pause();
        window.open(data?.actionUrl, '_blank');
      }
    });

    // Cleanup
    return () => {
      exampleTarget.removeEventListener('targetFound', handleTargetFound);
      exampleTarget.removeEventListener('targetLost', handleTargetLost);
      window.removeEventListener('click', handleWindowClick);
    };
    // eslint-disable-next-line
  }, [haveClick, firstView]);

  function take_photo() {
    const video = document.getElementById('myvideo');
    video.pause();
    const camera =
      sceneWrapperRef.current.children[
        sceneWrapperRef.current.children.length - 1
      ];
    const canvas = document.createElement('canvas');

    var imgDataElement = document
      .querySelector('a-scene')
      .components.screenshot.getCanvas('perspective');
    const ctx = canvas.getContext('2d'); //draw image to canvas. scale to target dimensions
    const imgDataWidth = imgDataElement.getAttribute('width');
    const imgDataHeight = imgDataElement.getAttribute('height');

    canvas.width = imgDataWidth;
    canvas.height = imgDataHeight;

    // Определяем размеры области, которую нужно отрисовать из камеры
    const scale = imgDataWidth / camera.clientWidth;
    const scaledCameraHeight = camera.clientHeight * scale;
    const offsetY = (scaledCameraHeight - imgDataHeight) / 2;

    // Рисуем изображение с камеры, масштабируя и сдвигая его под размеры imgData
    ctx.drawImage(
      camera,
      0,
      -offsetY,
      camera.clientWidth * scale,
      scaledCameraHeight
    );

    ctx.drawImage(imgDataElement, 0, 0, imgDataWidth, imgDataHeight);

    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'screen';
    link.href = url;
    link.click();
    if (!firstView) video.play();
  }

  return (
    <>
      <div
        id="arScene"
        ref={sceneWrapperRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <a-scene
          ref={sceneRef}
          mindar-image={`imageTargetSrc:${data?.trigger?.filePath};uiScanning: no;filterMinCF:0.0001; filterBeta: 0.001;`}
          color-space="sRGB"
          embedded
          autoStart="true"
          renderer="colorManagement: true"
          vr-mode-ui="enabled: false"
          device-orientation-permission-ui="enabled: false"
          height="100%"
          width="100%"
          loading-screen="dotsColor: #ffffff; backgroundColor: #2d2d2d"
        >
          <a-assets>
            <img
              id="myimage"
              alt=""
              src={`https://storage.yandexcloud.net/zrenie-dev-bucket/%D0%A2%D0%B5%D1%81%D1%82%D1%8B%20%D0%B4%D0%BB%D1%8F%20%D0%B4%D0%B5%D0%BC%D0%BE/play-button.png`}
            />
            <video
              id="myvideo"
              // src={`${data?.filePath}`}
              type="video/mp4"
              loop={true}
              scale={1}
              playsInline
              crossOrigin="anonymous"
              controls
            />
          </a-assets>

          <a-camera
            position="0 0 0"
            look-controls="enabled: false"
            height="100%"
            width="100%"
            id="user-camera"
            className="user-camera"
            cursor="fuse: false; rayOrigin: mouse;"
            raycaster="near: 10; far: 10000; objects: .clickable"
          />

          <a-entity id="example-target" mindar-image-target="targetIndex: 0">
            <a-plane
              id="example-plane"
              class="clickable"
              src={`${haveClick ? '#myvideo' : '#myimage'}`}
              position={
                haveClick
                  ? `${data?.offsetX !== null ? data?.offsetX : 0} ${
                      data?.offsetY !== null ? data?.offsetY : 0
                    } ${data?.offsetZ !== null ? data?.offsetZ : 0}`
                  : '0 0 0'
              }
              scale={
                haveClick
                  ? `${data?.scale !== null ? data.scale : 1} ${
                      procent * (data?.scale !== null ? data.scale : 1)
                    } 1`
                  : '1 1 1'
              }
              rotation="0 0 0"
              transparent="true"
            />
          </a-entity>
        </a-scene>
        <PhotoMode take_photo={take_photo} />
      </div>
    </>
  );
};

export default MindARVideo;
