import React, { useEffect, useRef } from 'react';
import PhotoMode from './photo-mode';

function MindARModel({ data }) {
  const sceneRef = useRef(null);
  const sceneWrapperRef = useRef(null);

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems['mindar-image-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });

    // grab the entity reference
    const el = document.querySelector('a-entity');
    // pause - run at 0 speed
    el.setAttribute('animation-mixer', { timeScale: 0 });
    // play - run at normal speed(1)
    el.setAttribute('animation-mixer', { timeScale: 1 });
    return () => {
      arSystem.stop();
    };
  }, []);

  function take_photo() {
    // const img = document.getElementById("avatarModel");
    const camera =
      sceneWrapperRef.current.children[
        sceneWrapperRef.current.children.length - 1
      ];
    const canvas = document.createElement('canvas');

    canvas.width = camera.clientWidth;
    canvas.height = camera.clientHeight;
    var imgData = document
      .querySelector('a-scene')
      .components.screenshot.getCanvas('perspective');
    const ctx = canvas.getContext('2d'); //draw image to canvas. scale to target dimensions
    ctx.drawImage(camera, 0, 0, canvas.width, canvas.height);
    ctx.drawImage(imgData, 0, 0, canvas.width, canvas.height);

    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'screen';
    link.href = url;
    link.click();
  }

  return (
    <div
      ref={sceneWrapperRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <a-scene
        ref={sceneRef}
        mindar-image={`imageTargetSrc:${data?.trigger?.filePath};uiScanning: no;filterMinCF:0.0001; filterBeta: 0.001`}
        color-space="sRGB"
        embedded
        autoStart="false"
        renderer="colorManagement: true"
        vr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false"
        height="100%"
        width="100%"
        loading-screen="dotsColor: #ffffff; backgroundColor: #2d2d2d"
      >
        <a-assets>
          <a-asset-item
            id="avatarModel"
            response-type="arraybuffer"
            rotation="0 180 0"
            position="0 0 0"
            scale="0.01 0.01 0.01"
            src={data?.filePath}
          />
        </a-assets>

        <a-camera
          position="0 0 0"
          look-controls="enabled: false"
          height="100%"
          width="100%"
        />

        <a-entity mindar-image-target="targetIndex: 0">
          <a-gltf-model
            src="#avatarModel"
            rotation="0 0 0 "
            position={`${data?.offsetX !== null ? data?.offsetX : 0} ${
              data?.offsetY !== null ? data?.offsetY : 0
            } ${data?.offsetZ !== null ? data?.offsetZ : 0}`}
            scale={`${data?.scale !== null ? data?.scale : 1} ${
              data?.scale !== null ? data?.scale : 1
            } ${data?.scale !== null ? data?.scale : 1}`}
          />
        </a-entity>
      </a-scene>
      <PhotoMode take_photo={take_photo} />
    </div>
  );
}
export default MindARModel;
