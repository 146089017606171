import { useCallback, useEffect, useRef, useState } from 'react';

export function useMediaQuery(mediaQueries) {
  const mediaQueryLists = useRef(
    mediaQueries.map((mq) => window.matchMedia(mq))
  );

  const getMatchesMedia = useCallback(
    () => mediaQueryLists.current.map((mq) => mq.matches),
    []
  );

  const [matchedMedia, setMatchedMedia] = useState(getMatchesMedia);

  const handleChangeMedia = useCallback(
    () => setMatchedMedia(getMatchesMedia),
    [getMatchesMedia]
  );

  useEffect(() => {
    const mql = mediaQueryLists.current;

    try {
      mql.forEach((mq) => mq.addEventListener('change', handleChangeMedia));
    } catch {
      // for Safari prior to 14 version
      mql.forEach((mq) => mq.addListener(handleChangeMedia));
    }

    return () => {
      try {
        mql.forEach((mq) =>
          mq.removeEventListener('change', handleChangeMedia)
        );
      } catch {
        // for Safari prior to 14 version
        mql.forEach((mq) => mq.removeListener(handleChangeMedia));
      }
    };
  }, [handleChangeMedia]);

  return matchedMedia;
}
